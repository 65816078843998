import axios from 'axios';

export const login = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/auth/login`,
		data: payload,
	});

	return data;
};

export const createUser = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/users`,
		data: payload,
	});

	return data;
};

export const getUserSelf = async () => {
	const { data } = await axios.get(`/api/users/self`);
	return data;
};

export const getUsers = async () => {
	const { data } = await axios.get(`/api/users`);
	return data;
};

export const updateUserSelf = async (payload: any) => {
	const { data } = await axios({
		method: 'put',
		url: `/api/users/self`,
		data: payload,
	});

	return data;
};

export const editUser = async (id: string, payload: any) => {
	const { data } = await axios({
		method: 'put',
		url: `/api/users/${id}`,
		data: payload,
	});

	return data;
};

export const getOrganizations = async () => {
	const { data } = await axios.get(`/api/organizations`);
	return data;
};

export const getGlobalMessages = async (organizationId: string) => {
	const { data } = await axios.get(`/api/organizations/${organizationId}/global-messages`);
	return data;
};

export const createGlobalMessage = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/organizations/${payload.organization_id}/global-messages`,
		data: payload,
	});

	return data;
};

export const removeGlobalMessage = async (organizationId: string, id: string) => {
	const { data } = await axios({
		method: 'delete',
		url: `/api/organizations/${organizationId}/global-messages/${id}`,
		data: {},
	});

	return data;
};

export const toggleFeatures = async (payload: any) => {
	const { data } = await axios({
		method: 'put',
		url: `/api/features`,
		data: payload,
	});

	return data;
};

export const createOrganization = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/organizations`,
		data: payload,
	});

	return data;
};

export const editOrganization = async (id: string, payload: any) => {
	const { data } = await axios({
		method: 'put',
		url: `/api/organizations/${id}`,
		data: payload,
	});

	return data;
};

export const addUserToOrganization = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/organizations/users`,
		data: payload,
	});

	return data;
};

export const removeUserFromOrganization = async (userId: string, orgId: string) => {
	const { data } = await axios({
		method: 'delete',
		url: `/api/organizations/${orgId}/wingops-users/${userId}`,
		data: {},
	});

	return data;
};

export const getWingopsUsers = async () => {
	const { data } = await axios.get(`/api/wingops-users`);
	return data;
};

export const copyAircraft = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/aircrafts/copy-aircraft`,
		data: payload,
	});

	return data;
};

export const copyOrganization = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/organizations/copy-organization`,
		data: payload,
	});

	return data;
};

export const clearDemoData = async (payload: any) => {
	const { data } = await axios({
		method: 'post',
		url: `/api/organizations/clear-demo-data`,
		data: payload,
	});

	return data;
};

export const getOrganization = async (id: string) => {
	const { data } = await axios.get(`/api/organizations/${id}`);
	return data;
};

export const getAircrafts = async () => {
	const { data } = await axios.get(`/api/aircrafts`);
	return data;
};

export const getAircraft = async (id: string) => {
	const { data } = await axios.get(`/api/aircrafts/${id}`);
	return data;
};
