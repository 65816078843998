import React from 'react';
import { Form, Input, Button, message } from 'antd';
import styled from 'styled-components';
import { login } from '../api';

import logo from '../assets/images/wingops-logo-blue.svg';

const LoginWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -160px 0 0 -160px;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 320px;
	height: 372px;
	padding: 36px;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
`;

const LoginButton = styled(Button)`
	width: 100%;
`;

const LoginForm = styled(Form)`
	width: 100%;
`;

const Logo = styled.img`
	width: 200px;
	margin-bottom: 34px;
`;

const Login = () => {
	const onFinish = async (values: any) => {
		try {
			await login(values);
			window.location.href = '/';
		} catch (err) {
			message.error('Wrong email or password!');
		}
	};

	return (
		<LoginWrapper>
			<Logo src={logo} />
			<LoginForm name="login" layout="vertical" onFinish={onFinish}>
				<Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please insert Email!' }]}>
					<Input />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Please insert password!' }]}
				>
					<Input.Password />
				</Form.Item>

				<Form.Item>
					<LoginButton type="primary" htmlType="submit">
						Log in
					</LoginButton>
				</Form.Item>
			</LoginForm>
		</LoginWrapper>
	);
};

export default Login;
